import { Js, ExternalLinkSquareAlt, Github, Html5, NodeJs } from "@vicons/fa"
// import portfolioDesign from './common/portfolio-design';
// import speechToText from './common/speech-to-text';
// import textToSpeech from './common/text-to-speech';
// import webCalculator from './common/web-calculator';
// import worldTimeReact from './common/world-time-react';

export default [
    {
    title: "openAIChat",
    des: "<p>This app is just example app of how I can use the OpenAI API to communicate with the davinci-003 model in chat-like fashion. Currently the API key is innactive, because of quota stuff.</p>",
    headIcon: 'chat',
    links: [
        {
            link: "https://github.com/UmrenTV/openAIChat",
            icon: Github,
            tooltip: 'Open In Github'
        },
        {
            link: "https://open-ai-chat-blush.vercel.app/",
            icon: ExternalLinkSquareAlt,
            tooltip: 'Open Link'
        }
    ],
    techs: [
        {
            name: "html&css",
            icon: Html5
        },
        {
            name: "express",
            icon: NodeJs
        },
        {
            name: "Javascript",
            icon: Js
        }
    ]
},
    // worldTimeReact,
    // portfolioDesign,
    // webCalculator,
    // speechToText,
    // textToSpeech,
];
