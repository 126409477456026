import { ExternalLinkSquareAlt /** Github **/ } from '@vicons/fa';

export default {
    works: [
        {
            overline: 'Vue/Nuxt/Strapi project',
            title: 'Official Website',
            des: 'In this website I had to re-create the old official Kimicom website from new figma design, and optimize for SSR, SEO, compatibility, usability and speed. The website has Strapi CMS for managing everything that is written on the website and also multi language localization that loads automatically on different domains (.de, .se, .net, .mk).',
            techs: ['Vue', 'Nuxt', 'SCSS', 'Strapi', 'GraphQL', 'NodeJS'],
            links: [
                {
                    link: 'https://kimicom.net/',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://kimicom.net',
            img: 'https://i.imgur.com/H5sSqjM.png',
        },
        {
            overline: 'Unreal Engine 5 Game ',
            title: 'Crystal Pops Game',
            des: "In CrystalPops the goal of the player, who interacts with the 3D world in third person, is to find and collect the Crystals, solve puzzles, and discover hidden NFT's through diffucult and hidden puzzles. Very kid friendly, with 0 violence, and lots of smarts required even for adult.",
            techs: ['Unreal Engine 5', 'Firebase', 'Python'],
            links: [
                {
                    link: 'https://crystalpops.com/download',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://crystalpops.com/download',
            img: 'https://i.imgur.com/NXrKuWh.png',
        },
        {
            overline: 'ElectronJS App',
            title: 'Crystal Pops Launcher',
            des: 'This Application is used as Game Launcher, that allows you to login with <a href="https://niftygateway.com" target="_blank">NiftyGateway</a>, check if you have CrystalPops NFT in your wallet, detects whether the game is installed, and launch it if so. It also allows you to download the game if you dont have it installed yet.',
            techs: ['VueJS', 'ElectronJS', 'NodeJS', 'Firebase'],
            links: [
                {
                    link: 'https://crystalpops.com/download',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://crystalpops.com/download',
            img: 'https://i.imgur.com/daMVbFF.png',
        },
        {
            overline: 'NuxtJS / VueJS App',
            title: 'Crystal Pops Website',
            des: 'A very simple website that allows you to download the game/launcher, gives you information about the game/team, and has mini admin panel to manage the hidden NFTs requests.',
            techs: ['Vue', 'Nuxt', 'SCSS', 'Firebase'],
            links: [
                {
                    link: 'https://crystalpops.com',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://crystalpops.com',
            img: 'https://i.imgur.com/LGUPzB4.png',
        },
        {
            overline: 'VueJS/NuxtJS/Strapi App',
            title: `HelixAnimation`,
            des: 'They were born as company 12 years ago out of passion for creating spellbinding visuals, HelixAnimation are a full-service studio exclusively focused on medical and scientific 3D visualizations',
            techs: ['Vue', 'Nuxt', 'Strapi', 'GraphQL'],
            links: [
                {
                    link: 'https://helixanimation.com',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://helixanimation.com',
            img: 'https://i.imgur.com/SIgHVF5.png',
        },
        // {
        //     overline: 'Personal Website',
        //     title: 'Portfolio',
        //     des: "It's is a new cool look for my portfolio based on Brittany's design. I created Ground Up using the Technology Called Vue to Create This Portfolio.",
        //     techs: ['vue', 'PWA', 'javascript'],
        //     links: [
        //         {
        //             link: 'https://github.com/BroJenuel/jenuel-portfolio-v2',
        //             icon: Github,
        //             tooltip: 'Open In Github',
        //         },
        //         {
        //             link: 'https://BroJenuel.com',
        //             icon: ExternalLinkSquareAlt,
        //             tooltip: 'Open Link',
        //         },
        //     ],
        //     imageLink: 'https://BroJenuel.com',
        //     img: 'https://i.imgur.com/ygEsq4Kl.png',
        // },
    ],
};
